mat-card {
  margin-bottom: 20px;
  border: 1px solid rgb(0, 0, 0, 0.1);
  box-shadow: none !important;
  border-radius: 8px !important;
  color: #181932 !important;
}

mat-card-header {
  display: flex;
  flex-direction: column;
}

mat-card-title {
  display: flex;
  flex-direction: row;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600 !important;
  font-style: normal !important;
  font-variation-settings:
    "slnt" 0;

  .icon {
    font-size: 16px !important;
    padding-left: 2px;
  }
}

mat-card-subtitle {
  font-size: 12px !important;
  color: #5A5A78 !important;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400 !important;
  font-style: normal !important;
  font-variation-settings:
    "slnt" 0;
}

//mat-card-actions {
//  button {
//    mat-icon {
//      font-size: 24px !important;
//    }
//  }
//}

.card-status {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 24px;
  padding-top: 6px;
  padding-left: 16px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 500;
}

.content-separator {
  margin: 15px 15px !important;
}

.actions-separator {
  margin: 0 15px !important;
}

mat-card-actions {
  justify-content: space-between;
}
